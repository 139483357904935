// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-aboutuspage-js": () => import("./../../../src/templates/aboutuspage.js" /* webpackChunkName: "component---src-templates-aboutuspage-js" */),
  "component---src-templates-apipage-js": () => import("./../../../src/templates/apipage.js" /* webpackChunkName: "component---src-templates-apipage-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blogpage-js": () => import("./../../../src/templates/blogpage.js" /* webpackChunkName: "component---src-templates-blogpage-js" */),
  "component---src-templates-cgvpage-js": () => import("./../../../src/templates/cgvpage.js" /* webpackChunkName: "component---src-templates-cgvpage-js" */),
  "component---src-templates-customizationpage-js": () => import("./../../../src/templates/customizationpage.js" /* webpackChunkName: "component---src-templates-customizationpage-js" */),
  "component---src-templates-fieldspage-js": () => import("./../../../src/templates/fieldspage.js" /* webpackChunkName: "component---src-templates-fieldspage-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-integration-tag-js": () => import("./../../../src/templates/integrationTag.js" /* webpackChunkName: "component---src-templates-integration-tag-js" */),
  "component---src-templates-integrationspage-js": () => import("./../../../src/templates/integrationspage.js" /* webpackChunkName: "component---src-templates-integrationspage-js" */),
  "component---src-templates-lead-js": () => import("./../../../src/templates/lead.js" /* webpackChunkName: "component---src-templates-lead-js" */),
  "component---src-templates-legalpage-js": () => import("./../../../src/templates/legalpage.js" /* webpackChunkName: "component---src-templates-legalpage-js" */),
  "component---src-templates-main-router-js": () => import("./../../../src/templates/mainRouter.js" /* webpackChunkName: "component---src-templates-main-router-js" */),
  "component---src-templates-offer-factory-js": () => import("./../../../src/templates/offer-factory.js" /* webpackChunkName: "component---src-templates-offer-factory-js" */),
  "component---src-templates-offer-interventionassets-js": () => import("./../../../src/templates/offer-interventionassets.js" /* webpackChunkName: "component---src-templates-offer-interventionassets-js" */),
  "component---src-templates-offer-oms-multidelivery-js": () => import("./../../../src/templates/offer-oms_multidelivery.js" /* webpackChunkName: "component---src-templates-offer-oms-multidelivery-js" */),
  "component---src-templates-offer-procurement-js": () => import("./../../../src/templates/offer-procurement.js" /* webpackChunkName: "component---src-templates-offer-procurement-js" */),
  "component---src-templates-offer-stocksagile-js": () => import("./../../../src/templates/offer-stocksagile.js" /* webpackChunkName: "component---src-templates-offer-stocksagile-js" */),
  "component---src-templates-partnershipspage-js": () => import("./../../../src/templates/partnershipspage.js" /* webpackChunkName: "component---src-templates-partnershipspage-js" */),
  "component---src-templates-pricepage-js": () => import("./../../../src/templates/pricepage.js" /* webpackChunkName: "component---src-templates-pricepage-js" */),
  "component---src-templates-servicespage-js": () => import("./../../../src/templates/servicespage.js" /* webpackChunkName: "component---src-templates-servicespage-js" */),
  "component---src-templates-solutionspage-js": () => import("./../../../src/templates/solutionspage.js" /* webpackChunkName: "component---src-templates-solutionspage-js" */),
  "component---src-templates-usecase-detailspage-js": () => import("./../../../src/templates/usecaseDetailspage.js" /* webpackChunkName: "component---src-templates-usecase-detailspage-js" */),
  "component---src-templates-usecasespage-js": () => import("./../../../src/templates/usecasespage.js" /* webpackChunkName: "component---src-templates-usecasespage-js" */),
  "component---src-templates-vertical-circular-economy-js": () => import("./../../../src/templates/vertical-circular-economy.js" /* webpackChunkName: "component---src-templates-vertical-circular-economy-js" */),
  "component---src-templates-vertical-construction-js": () => import("./../../../src/templates/vertical-construction.js" /* webpackChunkName: "component---src-templates-vertical-construction-js" */),
  "component---src-templates-vertical-fieldservicemanagement-js": () => import("./../../../src/templates/vertical-fieldservicemanagement.js" /* webpackChunkName: "component---src-templates-vertical-fieldservicemanagement-js" */),
  "component---src-templates-vertical-industry-js": () => import("./../../../src/templates/vertical-industry.js" /* webpackChunkName: "component---src-templates-vertical-industry-js" */),
  "component---src-templates-vertical-retail-js": () => import("./../../../src/templates/vertical-retail.js" /* webpackChunkName: "component---src-templates-vertical-retail-js" */)
}

